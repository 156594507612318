import { Icon } from '../../../../../components/atoms';
import {
  Icons,
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { Colors, Size } from '../../../../../components/style';
import { Constants, DISPATCH_CONSTANTS, Utils } from '../../../../../constants';
import { extractImageName } from '../../../../../providers/FileUploadProvider.utils';
import { cleanGridStyle } from '../../../../../utils/styleUtils';
import { parseStringModifiers } from '../../../../../utils/utils';

export const brandManagementKeys = {
  brand: {
    self: 'brand',
    alias: 'alias',
    id: 'brand.id',
    image: 'br.image',
    name: 'br.name',
    isContracted: 'isContracted',
  },
  brandGroup: {
    self: 'brandGroup',
    name: 'bg.name',
    id: 'bg.id',
  },
  contract: {
    self: 'contract',
    id: 'id.contract',
    count: 'contractCount',
    endDate: 'endDate',
    name: 'con.name',
    description: 'con.description',
    file: 'con.file',
    addedAt: 'con.addedAt',
    addedBy: {
      name: 'con.addedBy.name',
      image: 'con.addedBy.image',
    },
  },
  hasSorters: 'hasSorters',
  industry: 'industry',
  plan: {
    self: 'plan',
    rowId: 'plan.rowId',
    planTypeId: 'plan.planTypeId',
    name: 'plan.name',
    endDate: 'endDate',
    count: 'plan.count',
    setupFee: 'setupFee',
    priceMonthly: 'priceMonthly',
    priceCatch: 'priceCatch',
    start: 'start',
    end: 'end',
    note: 'note',
    addedAt: 'addedAt',
    addedBy: {
      name: 'plan.addedBy.name',
      image: 'plan.addedBy.image',
    },
    status: {
      self: 'plan.status',
      response: 'plan.status.response',
    },
  },
  representative: {
    self: 'representative',
    email: 'email',
    image: 'rep.image',
    name: 'rep.name',
  },
  service: {
    count: 'serviceCount',
    name: 'title',
    description: 'detail',
    addedBy: {
      name: 'ser.addedBy.name',
      image: 'ser.addedBy.image',
    },
    addedAt: 'ser.addedAt',
    icon: 'icon',
    id: 'id',
    role_id: 'ser.role_id',
    isEnabled: 'ser.isEnabled',
    roleKey: 'ser.key',
    roleName: 'ser.key',
  },
  user: {
    id: 'user.id',
    count: 'userCount',
    name: 'user.name',
    firstName: 'user.firstName',
    lastName: 'user.lastName',
    image: 'user.image',
    email: 'user.email',
    isMainContact: 'user.isMainContact',
    addedAt: 'user.createdAt',
    addedBy: {
      name: 'user.addedBy.name',
      image: 'user.addedBy.image',
    },
    services: 'services',
    serviceId: 'services.id',
  },
  invoice: {
    self: 'invoice',
    id: 'inv.id',
    alias: 'inv.alias',
    amount: 'inv.amount',
    addedAt: 'inv.addedAt',
    addedBy: {
      name: 'inv.addedBy.name',
      image: 'inv.addedBy.image',
    },
    count: 'invoiceCount',
    date: 'inv.date',
    name: 'inv.name',
    status: {
      response: 'inv.status.response',
      self: 'inv.status',
      name: 'inv.status.name',
      style: 'inv.status.style',
      value: 'inv.status.value',
    },
    file: 'inv.file',
  },
};

const keys = brandManagementKeys;

export const DC = {
  ...DISPATCH_CONSTANTS,
  CONTRACTED: 'CONTRACTED',
  BRAND_GROUP: 'BRAND_GROUP',
  INDUSTRY: 'INDUSTRY',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    param: 'search',
  },
  [DC.CONTRACTED]: {
    type: FilterTypes.dropdown,
    icon: Icons.FileCheck2,
    param: 'contracted',
  },
  [DC.BRAND_GROUP]: {
    type: FilterTypes.dropdown,
    icon: Icons.Building,
    param: 'brandGroup',
  },
  [DC.INDUSTRY]: {
    type: FilterTypes.dropdown,
    icon: Icons.Factory,
    param: 'industry',
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
  [DC.RESET]: {
    type: FilterTypes.button,
    icon: Icons.X,
  },
};

export const headers = {
  [keys.brand.image]: 'Brand',
  [keys.brand.name]: '',
  [keys.brandGroup.name]: 'Brand group',
  [keys.industry]: 'Industry',
  [keys.contract.endDate]: 'Contract end',
  [keys.plan.name]: 'Plan',
  [keys.service.count]: (
    <Icon
      name={Icons.Component}
      size={Size.Icon._M}
      color={Colors.Neutral._500}
    />
  ),
  [keys.user.count]: <Icon name={Icons.Users} size={Size.Icon._M} />,
};

export const grid = {
  gridTemplateColumns: cleanGridStyle(`
    //  brand //
    68px
    //  brand //
    minmax(190px, 0.85fr)
    // brand group //
    minmax(40px, 0.75fr)
    // industry //
    minmax(60px, 0.5fr)
    // contract end //
    minmax(60px, 0.35fr)
    // plan //
    minmax(60px, 0.35fr)
    // services //
    72px
    // user count //
    72px
    `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const initialFilterValues = {
  [DC.CONTRACTED]: '',
  [DC.BRAND_GROUP]: '',
  [DC.INDUSTRY]: '',
};

export const titles = {
  [DC.CONTRACTED]: 'Contracted',
  [DC.BRAND_GROUP]: 'Brand group',
  [DC.INDUSTRY]: 'Industry',
};

export const allOptions = {
  [DC.CONTRACTED]: { label: 'All', value: Constants.all },
  [DC.BRAND_GROUP]: { label: 'All brand groups', value: Constants.all },
  [DC.INDUSTRY]: { label: 'All industries', value: Constants.all },
};

export const noResultsState = {
  noData: onClick => ({
    icon: NoData.Cases,
    title: 'No brands',
    description: 'As soon as we sign a new brand, you will find it here',
  }),
  noSearchResults: {
    icon: NoResults.Cases,
    title: 'No results found',
    description: 'Please try another search or view all brands',
  },
  noServices: {
    icon: NoData.Services,
    title: 'No services',
    description: 'All services have been assigned',
  },
};

export const tabs = {
  services: 'Services',
  users: 'Users',
  plans: 'Plans',
  contracts: 'Contracts',
  invoices: 'Invoices',
};

export const tabURIs = {
  [tabs.services]: 'services',
  [tabs.users]: 'clients',
  [tabs.plans]: 'subscription_plans',
  [tabs.contracts]: 'contracts',
  [tabs.invoices]: 'invoices',
};

export const tabCounts = {
  [tabs.services]: keys.service.count,
  [tabs.users]: keys.user.count,
  [tabs.plans]: keys.plan.count,
  [tabs.contracts]: keys.contract.count,
  [tabs.invoices]: keys.invoice.count,
};

export const tabActionText = {
  [tabs.services]: 'Add service',
  [tabs.users]: 'Add user',
  [tabs.plans]: 'Add plan',
  [tabs.contracts]: 'Add contract',
  [tabs.invoices]: 'Add invoice',
};

export const snapshotHeaders = {
  [tabs.services]: {
    [keys.service.name]: 'Service',
    [keys.service.description]: 'Description',
    [keys.service.addedAt]: 'Added at',
    [keys.service.addedBy.name]: 'Added by',
    options: '',
  },
  [tabs.users]: {
    expansion: '',
    [keys.user.name]: 'Name',
    [keys.user.email]: 'Email',
    [keys.user.addedAt]: 'Added at',
    [keys.user.addedBy]: 'Added by',
    [keys.user.services]: 'Services',
    options: '',
  },
  [tabs.contracts]: {
    [keys.contract.name]: 'Name',
    [keys.contract.description]: 'Description',
    [keys.contract.addedAt]: 'Added at',
    [keys.contract.addedBy]: 'Added by',
    options: '',
  },
  [tabs.plans]: {
    [keys.plan.name]: 'Name',
    [keys.plan.setupFee]: 'Setup fee',
    [keys.plan.priceMonthly]: 'Price/Month',
    [keys.plan.priceCatch]: 'Price/Catch',
    [keys.plan.start]: 'Start',
    [keys.plan.end]: 'End',
    [keys.plan.note]: 'Note',
    [keys.plan.addedAt]: 'Added at',
    [keys.plan.addedBy.name]: 'Added by',
    [keys.plan.status]: 'Status',
    options: '',
  },
  [tabs.invoices]: {
    [keys.invoice.name]: 'Name',
    [keys.invoice.date]: 'Date',
    [keys.invoice.amount]: 'Amount',
    [keys.invoice.addedAt]: 'Added at',
    [keys.invoice.addedBy.name]: 'Added by',
    [keys.invoice.status]: 'Status',
    options: '',
  },
};

const optionsColumnWidth = '48px';

export const snapshotGrids = {
  [tabs.services]: {
    gridTemplateColumns: cleanGridStyle(`
    //  service //
    minmax(200px, 0.25fr)
    // description //
    minmax(80px, 0.9fr)
    // added at //
    minmax(80px, 0.25fr)
    // added by //
    minmax(100px, 0.35fr)
    // options //
    ${optionsColumnWidth}
    `),
    gridAutoRows: '56px 0px',
    rowHeight: '56px',
  },
  [tabs.users]: {
    gridTemplateColumns: cleanGridStyle(`
    //  expansion //
    40px
    //  name //
    minmax(100px, 1fr)
    // email //
    minmax(100px, 1fr)
    // added at //
    minmax(80px, 0.4fr)
    // added by //
    minmax(80px, 0.75fr)
    // services //
    minmax(180px, 0.75fr)
    // options //
    ${optionsColumnWidth}
    `),
    gridAutoRows: '56px 0px',
    rowHeight: '56px',
  },
  [tabs.plans]: {
    gridTemplateColumns: cleanGridStyle(`
    //  name //
    minmax(100px, 1fr)
    // setup fee //
    minmax(100px, 1fr)
    // price/monthly //
    minmax(60px, 1fr)
    // price/catch //
    minmax(60px, 1fr)
    // start //
    minmax(60px, 1fr)
    // end //
    minmax(60px, 1fr)
    // note //
    minmax(60px, 1fr)
    // added at //
    minmax(80px, 1fr)
    // added by //
    minmax(80px, 1fr)
    // status //
    minmax(120px, 1fr)
    // options //
    ${optionsColumnWidth}
    `),
    gridAutoRows: '56px 0px',
    rowHeight: '56px',
  },
  [tabs.contracts]: {
    gridTemplateColumns: cleanGridStyle(`
    //  name //
    minmax(200px, 0.4fr)
    // description //
    minmax(80px, 1fr)
    // added at //
    minmax(80px, 0.25fr)
    // added by //
    minmax(80px, 0.5fr)
    // options //
    ${optionsColumnWidth}
    `),
    gridAutoRows: '56px 0px',
    rowHeight: '56px',
  },
  [tabs.invoices]: {
    gridTemplateColumns: cleanGridStyle(`
    //  name //
    minmax(160px, 0.75fr)
    // date //
    minmax(120px, 0.5fr)
    // amount //
    minmax(100px, 0.5fr)
    // added at //
    minmax(80px, 0.5fr)
    // added by //
    minmax(80px, 1fr)
    // status //
    minmax(120px, 0.25fr)
    // options //
    ${optionsColumnWidth}
    `),
    gridAutoRows: '56px 0px',
    rowHeight: '56px',
  },
};

export const noResultsStateSnapshot = {
  noData: onClick => ({
    icon: NoData.Cases,
    title: 'No results',
    description: 'Something went wrong, or there is no data to display',
  }),
  noSearchResults: {
    icon: NoResults.Cases,
    description: 'Something went wrong, or there is no data to display',
    title: 'No results',
  },
};

export const userExpansionHeaders = {
  space: '',
  [brandManagementKeys.service.name]: 'Service',
  [brandManagementKeys.service.addedAt]: 'Date added',
  [brandManagementKeys.service.addedBy.name]: 'Added by',
  [brandManagementKeys.service.roleName]: 'Role',
};

export const usersExpansionGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // padding //
  16px
  // service icon & title //
  minmax(180px , 1fr)
  // date added //
  minmax(60px, 1fr)
  //  name //
  minmax(60px , 1fr)
  // role //
  minmax(80px , 110px)`),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

const errorText = {
  [Constants.FileUpload.extensionError]: parseStringModifiers(
    Constants.FileUpload.uploadTypeUtils.brand_contract.extensionError
  ),
  [Constants.FileUpload.uploadError]: parseStringModifiers(
    `There was an error uploading this file.\nPlease try again.`
  ),
  [Constants.FileUpload.unexpectedError]: parseStringModifiers(
    `There was an error uploading this file.\nPlease try again.`
  ),
  [Constants.FileUpload.fileSizeError]: parseStringModifiers(
    `${Constants.FileUpload.uploadTypeUtils.brand_contract.fileSizeError}\nPlease try again.`
  ),
};

export const uploadStatusContent = ({
  fileState,
  resetFileUpload,
  preexistingFile,
  enableUpload,
  isNotImage,
}) => {
  const { files, isUploading, completedUpload, uploadSuccess, errorType } =
    fileState;

  if (!enableUpload) {
    if (isNotImage) {
      const currentFileName = extractImageName(
        preexistingFile,
        isNotImage.folder
      );
      return {
        icon: Icons.FileCheck,
        text: parseStringModifiers(
          `**File uploaded** \n\n The current file is: **${currentFileName}**`
        ),
      };
    }
    return {
      preexistingFile,
    };
  }

  if (!files) {
    return {
      icon: Icons.FileInput,
      text: parseStringModifiers(`Drag and drop your file \n\nor`),
      button: {
        onClick: Utils.emptyFunction,
        text: 'Browse files',
      },
    };
  }

  if (isUploading)
    return {
      title: 'Uploading...',
    };

  if (!completedUpload) {
    const numOfFiles = files.length;
    let text = `Your file: **${files[0].name}** is ready to import`;
    if (numOfFiles > 1)
      text = `Your **${numOfFiles}** files are ready to import`;
    return {
      icon: Icons.Upload,
      title: 'Upload ready',
      text: parseStringModifiers(text),
    };
  }

  if (!uploadSuccess)
    return {
      icon: Icons.Warning,
      title: 'Upload failed',
      text: errorText[errorType],
      button: {
        onClick: resetFileUpload,
        text: 'Try again',
      },
    };

  if (uploadSuccess)
    return {
      icon: Icons.CheckCircle,
      title: 'Uploading success',
      text: 'Your file has been successfully imported',
    };
};
