import { pick } from '../lib/js';

export const Pages = {
  login: 'login',
  setup2FA: 'setup2FA',
  verify2FA: 'verify2FA',
  forgotPassword: 'forgotPassword',
  resetPassword: 'resetPassword',
  register: 'register',
  setPassword: 'setPassword',
  user_settings: 'user_settings',
};

const {
  login,
  setup2FA,
  verify2FA,
  forgotPassword,
  setPassword,
  resetPassword,
  register,
  user_settings,
} = Pages;

export const URIs = {
  [login]: 'login',
  [setup2FA]: '2fa_setup',
  [verify2FA]: '2fa_verify',
  [forgotPassword]: 'forgot',
  [resetPassword]: 'reset/:token',
  [register]: 'register/:token',
  [user_settings]: 'user_settings',
};

export const Labels = {
  [user_settings]: 'Account',
};

export const NavURIs = {
  [login]: `/${URIs[login]}`,
  [setup2FA]: `/${URIs[setup2FA]}`,
  [verify2FA]: `/${URIs[verify2FA]}`,
  [forgotPassword]: `/${URIs[forgotPassword]}`,
  [resetPassword]: `/${URIs[resetPassword]}`,
  [register]: `/${URIs[register]}`,
  [user_settings]: `/${URIs[user_settings]}`,
};

export const publicRURIs = Object.values(URIs).map(path => ({ path }));
export const nonRedirects = Object.values(
  pick(URIs, [forgotPassword, setPassword, resetPassword, register])
).map(path => ({ path }));

export const userSettingsPath = `${URIs[Pages.user_settings]}/*`;
