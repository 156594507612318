import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import { Input } from '../../../../../../components/molecules/Input/Input';
import { Label } from '../../../../../../components/molecules/Label/Label';
import { CardLoader } from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { Table } from '../../../../../../components/organisms/Table/Table';
import { Colors } from '../../../../../../components/style';
import TableStatus from '../../../../../../components/templates/TableStatus/TableStatus';
import {
  Cancel,
  CardSectionTitle,
  PlanPrice,
  PlanTitle,
  PrimaryAction,
} from '../atoms/atoms';
import {
  AllServices,
  PlanDetails,
  ServicePermissions,
  invoicesTableComponents,
  usersTableComponents,
} from '../molecules/molecules';
import { Actions, AddUserActionsCSS, InvoicesTableContainer } from '../style';

export const Plan = ({ planTitle, planPrice, planDetails }) => {
  return (
    <Flex column gap="_S">
      <PlanTitle text={planTitle} />
      <PlanPrice text={planPrice} />
      <PlanDetails planDetails={planDetails} />
      <Label
        text="Current plan"
        size="_L"
        textColor={Colors.Primary._800}
        hasBorder
      />
    </Flex>
  );
};

export const Payment = () => {
  return (
    <Flex column>
      <Input
        size="_M"
        disabled
        hasError
        placeholder={'Card holder name'}
        name="asdf"
      />
      <Input
        size="_M"
        disabled
        hasError
        placeholder={'Card number'}
        name="qwer"
      />
      <Flex gap="_S">
        <Input
          size="_M"
          disabled
          hasError
          placeholder={'DD / YY'}
          name="opiu"
        />
        <Input
          size="_M"
          disabled
          hasError
          placeholder={'CCV / CVV'}
          name="aaa"
        />
      </Flex>
      <Actions left>
        <Cancel text="Cancel" disabled />
        <PrimaryAction text="Save" disabled />
      </Actions>
    </Flex>
  );
};

export const Invoices = ({
  headers,
  data,
  rowAction,
  grid,
  isLoading,
  isError,
  error,
  tableStatusProps,
}) => {
  return (
    <InvoicesTableContainer>
      <TableStatus
        className={'invoices-table'}
        isLoading={isLoading}
        isError={isError}
        error={error}
        {...tableStatusProps}
        table={
          <Table
            headers={headers}
            data={data}
            rowAction={rowAction}
            grid={grid}
            components={invoicesTableComponents}
          />
        }
        isInCard
      />
    </InvoicesTableContainer>
  );
};

export const UsersTable = ({
  headers,
  data,
  grid,
  expansionHeaders,
  expansionGrid,
  handleAction,
  options,
}) => {
  return (
    <Table
      headers={headers}
      data={data}
      grid={grid}
      components={usersTableComponents({
        handleAction,
        options,
        expansionHeaders,
        expansionGrid,
      })}
    />
  );
};

export const UserDetailsInput = ({ fields }) => {
  return (
    <Flex>
      <Flex gap="_S" column w>
        <CardSectionTitle text={'Personal details'} />
        <Flex column>
          {fields.map(field => (
            <Input {...field} size="_M" hasError />
          ))}
        </Flex>
      </Flex>
      <Flex w />
    </Flex>
  );
};

export const UserServicesPermissions = ({
  allServices,
  services,
  update,
  isLoading,
}) => {
  return (
    <Flex gap="_S" column>
      <CardSectionTitle text={'Services'} />
      <Flex gap="_XS" column>
        {isLoading ? (
          <>
            <Spacer w height={'_M'} />
            <CardLoader />
            <Spacer w height={'_M'} />
          </>
        ) : (
          <>
            <AllServices {...allServices} />
            {(services ?? []).map((service, index) => (
              <ServicePermissions
                service={service}
                index={index}
                update={update}
                key={index}
                isLast={index === services.length - 1}
              />
            ))}
          </>
        )}
      </Flex>
    </Flex>
  );
};

export const AddUserActions = ({ onCancel, disabled, isLoading }) => {
  return (
    <AddUserActionsCSS>
      <Flex gap="_S">
        <Cancel onCancel={onCancel} />
        <PrimaryAction
          disabled={disabled}
          text="Invite"
          isLoading={isLoading}
        />
      </Flex>
    </AddUserActionsCSS>
  );
};
