import { Route, Routes } from 'react-router-dom';
import { NavURIs, Pages } from '../../routes/variables';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import SetPassword from './SetPassword';
import Setup2FA from './Setup2FA';
import Verify2FA from './Verify2FA';

const { login, setup2FA, verify2FA, forgotPassword, resetPassword, register } =
  Pages;
const {
  [login]: loginPath,
  [setup2FA]: setup2FAPath,
  [verify2FA]: verify2FAPath,
  [forgotPassword]: forgotPath,
  [resetPassword]: resetPath,
  [register]: registerPath,
} = NavURIs;

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path={loginPath} element={<Login />} />
      <Route path={setup2FAPath} element={<Setup2FA />} />
      <Route path={verify2FAPath} element={<Verify2FA />} />
      <Route path={forgotPath} element={<ForgotPassword />} />
      <Route path={resetPath} element={<SetPassword page={resetPassword} />} />
      <Route path={registerPath} element={<SetPassword page={register} />} />
    </Routes>
  );
};
