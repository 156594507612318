import { API } from '../../lib/api';
import { Pages } from '../../routes/variables';
import { Fields } from '../controllers/variables';

const { forgotPassword } = Pages;
const { emailField, captchaResponseField } = Fields[forgotPassword];

export const requestPasswordReset = async ({
  [emailField]: emailValue,
  [captchaResponseField]: captchaResponseValue,
}) => {
  const body = {
    [emailField]: emailValue,
    [captchaResponseField]: captchaResponseValue,
  };

  const { data, status, error } = await API.POST(
    'request_password_reset',
    body
  );

  if (error || status !== 200) return false;
  return true;
};
