import { Icon, Text } from '../../atoms';
import { FontKeys, SizeConstants, UtilityColors } from '../../style';
import { Recipe } from './CheckBox.style';

export const CheckBox = ({
  variant = 'default',
  size = SizeConstants._M,
  selected,
  setSelected,
  id,
  label,
  disabled,
  color,
  ...props
}) => {
  const style = Recipe(variant)({ size, selected, disabled, color });

  return (
    <div
      onClick={disabled ? () => {} : setSelected(id)}
      style={style}
      {...props}
      className="checkbox"
    >
      <Icon {...style.check} />
      {label ? (
        <Text
          text={label}
          variant="Paragraph"
          size={size}
          weight={FontKeys.Weight.Regular}
          color={disabled ? UtilityColors.Disabled : color}
          whiteSpace="nowrap"
        />
      ) : null}
    </div>
  );
};
