import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../components/molecules/Button/Button';
import { ViewTitle } from '../../../../../components/templates/ViewHeader/components';
import { Constants } from '../../../../../constants';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { DateTypes, formatDate } from '../../../../../utils/timeUtils';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { NavURIs, Pages } from '../../../routes/variables';
import { useGetBrand } from '../api/getBrand';
import { BrandDetailsLayout } from '../components/BrandDetailsLayout';
import AddBrand from './AddBrand';
import SnapshotList from './SnapshotList';
import { brandManagementKeys, tabCounts, tabs } from './variables';

const { SELF, VIEW_BRANDS } = PermissionKeys.Admin.ADMIN_BRANDS;

const BrandDetails = () => {
  const [activeTab, setActiveTab] = useState(tabs.services);

  const [components, setComponents] = useState({});
  const [displayTabs, setDisplayTabs] = useState([]);

  const { brandId } = useParams();

  const { dismiss, showModal } = useModal();
  const { setHeaderChildren } = useHeader();
  const { setShowFooter } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasViewPermission = hasPermission(VIEW_BRANDS);
  const hasEditPermission = hasViewPermission;

  const { data, isLoading, isError, refresh } = useGetBrand({
    id: brandId,
    ignore: !hasViewPermission,
  });

  useDeepCompareEffect(() => {
    if (!data[brandManagementKeys.brand.name]) return;

    const header = data[brandManagementKeys.brand.name];

    const headerChildren = (
      <Flex justify="between" align="center" w>
        <ViewTitle text={header} backPage={NavURIs[Pages.admin_brands]} />
        <Button
          text="Edit brand info"
          onClick={showEditBrandModal}
          leftIcon={Icons.Edit3}
          variant="Secondary"
          size="_S"
        />
      </Flex>
    );

    setHeaderChildren(headerChildren);
  }, [data]);

  useEffect(() => {
    setShowFooter(false);
  }, []);

  useEffect(() => {
    if (data == null || isLoading || isError) return;

    const components = {};
    const addToComponents = tab => {
      const component = (
        <SnapshotList
          id={brandId}
          tab={tab}
          listTitle={tab}
          refreshBrandDetails={refresh}
        />
      );
      components[tab] = component;
    };

    const displayTabs = [];
    const addToDisplayTabs = tab => {
      const displayTab = {
        label: tab,
        value: tab,
        count: data[tabCounts[tab]] || 0,
      };
      displayTabs.push(displayTab);
    };

    Object.values(tabs).forEach(tab => {
      addToDisplayTabs(tab);
      addToComponents(tab);
    });

    setDisplayTabs(displayTabs);
    setComponents(components);
  }, [tabs, data]);

  const showEditBrandModal = () => {
    showModal(AddBrand, {
      onSuccess: refreshAndDismiss,
      mode: 'edit',
      brandData: data,
    });
  };

  const refreshAndDismiss = () => {
    refresh();
    dismiss();
  };

  const brandImage = data[brandManagementKeys.brand.image];

  const startColumnProps = {
    brandName: data[brandManagementKeys.brand.name],
    isContracted: data[brandManagementKeys.brand.isContracted],
    brandGroupName: data[brandManagementKeys.brandGroup.name],
    industry: data[brandManagementKeys.industry],
  };

  const contractEndDate = formatDate(
    data[brandManagementKeys.contract.endDate],
    DateTypes.ddmmyy
  );
  const contactName = data[brandManagementKeys.representative.name];
  const endColumnProps = {
    contractEnd: contractEndDate
      ? 'Contract end: ' + contractEndDate
      : Constants.empty,
    plan: data[brandManagementKeys.plan.name],
    contact: {
      contactName: contactName
        ? '**Contact:** ' + contactName
        : Constants.empty,
      username: contactName,
      email: data[brandManagementKeys.representative.email],
      image: data[brandManagementKeys.representative.image],
    },
  };

  return (
    <BrandDetailsLayout
      brandImage={brandImage}
      startColumnProps={startColumnProps}
      endColumnProps={endColumnProps}
      snapshotsProps={{
        displayTabs,
        component: components[activeTab],
        activeTab,
        setActiveTab,
      }}
    />
  );
};

export default BrandDetails;
