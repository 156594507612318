import { Icon } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { Image } from '../../../../../../components/atoms/Image/Image';
import { RadioButton } from '../../../../../../components/molecules';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import Avatar from '../../../../../../components/molecules/Avatar/Avatar';
import { Toggle } from '../../../../../../components/molecules/Toggle/Toggle';
import {
  ExpansionArrow,
  RowDateHorizontal,
  RowDynamicBadge,
  RowHumanReadableDate,
  RowLabel,
  RowOptionsMenu,
  RowSimpleText,
  Space,
  TableContractedBrandName,
  User,
  runIfHasProducts,
} from '../../../../../../components/organisms/Table/Table.exports';
import {
  Colors,
  Effects,
  Layout,
  Size,
} from '../../../../../../components/style';
import { Utils } from '../../../../../../constants';
import { parseStringModifiers } from '../../../../../../utils/utils';
import {
  Services,
  UserServicesSubtable,
} from '../../../../../brand/features/brand_settings/components';
import { brandManagementKeys, tabs } from '../../controllers/variables';
import { BaseText } from '../atoms';
import {
  GridGrayBG,
  PlanItemCSS,
  PricingGrid,
  ServiceIconName,
  ServiceRowCSS,
} from '../style';

const imageStyle = {
  width: '40px',
  minWidth: '40px',
  minHeight: '40px',
  height: '40px',
  borderRadius: Effects.Border_Radius._2XS,
  border: `1px solid ${Colors.Neutral._300}`,
  objectFit: 'contain',
};

const NonLoadingImage = item => {
  return (
    <div style={imageStyle}>
      <Image src={item[brandManagementKeys.brand.image]} />
    </div>
  );
};
export const tableComponents = {
  [brandManagementKeys.brand.image]: {
    display: NonLoadingImage,
  },
  [brandManagementKeys.brand.name]: {
    display: TableContractedBrandName(
      brandManagementKeys.brand.name,
      brandManagementKeys.brand.isContracted
    ),
  },
  [brandManagementKeys.brandGroup.name]: {
    display: RowSimpleText(brandManagementKeys.brandGroup.name, 'bold'),
  },
  [brandManagementKeys.industry]: {
    display: RowSimpleText(brandManagementKeys.industry, 'bold'),
  },
  [brandManagementKeys.contract.endDate]: {
    display: RowDateHorizontal(brandManagementKeys.contract.endDate, {
      time: false,
    }),
  },
  [brandManagementKeys.plan.name]: {
    display: RowLabel(brandManagementKeys.plan.name, {
      color: Colors.Neutral._900,
      hasBorder: true,
      noPadding: false,
      size: '_S',
    }),
  },
  [brandManagementKeys.service.count]: {
    display: RowSimpleText(brandManagementKeys.service.count, 'bold'),
  },
  [brandManagementKeys.user.count]: {
    display: RowSimpleText(brandManagementKeys.user.count, 'bold'),
  },
};

export const ContactCard = ({ contactName, username, email, image }) => {
  return (
    <Flex gap="_XS">
      <Flex column align="end">
        <BaseText text={parseStringModifiers(contactName)} bold={false} />
        <BaseText text={email} color={Colors.Neutral._500} />
      </Flex>
      {image || username ? (
        <Avatar size={'_S'} image={image} username={username} />
      ) : null}
    </Flex>
  );
};

const servicesTableComponents = ({ options, optionMenuOptionClick }) => ({
  [brandManagementKeys.service.name]: {
    display: RowLabel(brandManagementKeys.service.name, {
      iconLeft: brandManagementKeys.service.icon,
    }),
  },
  [brandManagementKeys.service.description]: {
    display: RowSimpleText(brandManagementKeys.service.description),
  },
  [brandManagementKeys.service.addedAt]: {
    display: RowDateHorizontal(brandManagementKeys.service.addedAt, {
      time: false,
    }),
  },
  [brandManagementKeys.service.addedBy.name]: {
    display: User(
      brandManagementKeys.service.addedBy.name,
      brandManagementKeys.service.addedBy.image
    ),
  },
  options: RowOptionsMenu({
    options,
    optionClick: optionMenuOptionClick,
  }),
});

const usersTableComponents = ({
  options,
  optionMenuOptionClick,
  expansionHeaders,
  expansionGrid,
}) => ({
  expansion: {
    display: ExpansionArrow({ countKey: brandManagementKeys.user.services }),
    action: runIfHasProducts({
      action: Utils.expandRow,
      countKey: brandManagementKeys.user.services,
    }),
  },
  [brandManagementKeys.user.name]: {
    display: User(
      brandManagementKeys.user.name,
      brandManagementKeys.user.image
    ),
  },
  [brandManagementKeys.user.email]: {
    display: RowSimpleText(brandManagementKeys.user.email, 'bold'),
  },
  [brandManagementKeys.user.addedAt]: {
    display: RowDateHorizontal(brandManagementKeys.user.addedAt, {
      time: false,
    }),
  },
  [brandManagementKeys.user.addedBy]: {
    display: User(
      brandManagementKeys.user.addedBy.name,
      brandManagementKeys.user.addedBy.image
    ),
  },
  [brandManagementKeys.user.services]: {
    display: Services,
  },
  options: RowOptionsMenu({
    options,
    optionClick: optionMenuOptionClick,
  }),
  expand: UserServicesSubtable({
    headers: expansionHeaders,
    grid: expansionGrid,
    components: userSubtableComponents,
  }),
});

const plansTableComponents = ({ options, optionMenuOptionClick }) => ({
  [brandManagementKeys.plan.name]: {
    display: RowSimpleText(brandManagementKeys.plan.name, 'bold', {
      blue: true,
    }),
  },
  [brandManagementKeys.plan.setupFee]: {
    display: RowSimpleText(brandManagementKeys.plan.setupFee, 'bold'),
  },
  [brandManagementKeys.plan.priceMonthly]: {
    display: RowSimpleText(brandManagementKeys.plan.priceMonthly, 'bold'),
  },
  [brandManagementKeys.plan.priceCatch]: {
    display: RowSimpleText(brandManagementKeys.plan.priceCatch, 'bold'),
  },
  [brandManagementKeys.plan.start]: {
    display: RowSimpleText(brandManagementKeys.plan.start, 'bold'),
  },
  [brandManagementKeys.plan.end]: {
    display: RowSimpleText(brandManagementKeys.plan.end, 'bold'),
  },
  [brandManagementKeys.plan.note]: {
    display: RowSimpleText(brandManagementKeys.plan.note),
  },
  [brandManagementKeys.plan.addedAt]: {
    display: RowDateHorizontal(brandManagementKeys.plan.addedAt, {
      time: false,
    }),
  },
  [brandManagementKeys.plan.addedBy.name]: {
    display: User(
      brandManagementKeys.plan.addedBy.name,
      brandManagementKeys.plan.addedBy.image
    ),
  },
  [brandManagementKeys.plan.status]: {
    display: RowDynamicBadge(brandManagementKeys.plan.status.self),
  },
  options: RowOptionsMenu({
    options,
    optionClick: optionMenuOptionClick,
  }),
});

const contractsTableComponents = ({ options, optionMenuOptionClick }) => ({
  [brandManagementKeys.contract.name]: {
    display: RowSimpleText(brandManagementKeys.contract.name, 'bold', {
      blue: true,
    }),
  },
  [brandManagementKeys.contract.description]: {
    display: RowSimpleText(brandManagementKeys.contract.description),
  },
  [brandManagementKeys.contract.addedAt]: {
    display: RowDateHorizontal(brandManagementKeys.contract.addedAt, {
      time: false,
    }),
  },
  [brandManagementKeys.contract.addedBy]: {
    display: User(
      brandManagementKeys.contract.addedBy.name,
      brandManagementKeys.contract.addedBy.image
    ),
  },
  options: RowOptionsMenu({
    options,
    optionClick: optionMenuOptionClick,
  }),
});

const invoicesTableComponents = ({ options, optionMenuOptionClick }) => ({
  [brandManagementKeys.invoice.name]: {
    display: RowSimpleText(brandManagementKeys.invoice.name, 'bold', {
      blue: true,
    }),
  },
  [brandManagementKeys.invoice.date]: {
    display: RowDateHorizontal(brandManagementKeys.invoice.date, {
      time: false,
      defaultToString: true,
    }),
  },
  [brandManagementKeys.invoice.amount]: {
    display: RowSimpleText(brandManagementKeys.invoice.amount, 'bold'),
  },
  [brandManagementKeys.invoice.addedAt]: {
    display: RowDateHorizontal(brandManagementKeys.invoice.addedAt, {
      time: false,
    }),
  },
  [brandManagementKeys.invoice.addedBy.name]: {
    display: User(
      brandManagementKeys.invoice.addedBy.name,
      brandManagementKeys.invoice.addedBy.image
    ),
  },
  [brandManagementKeys.invoice.status]: {
    display: RowDynamicBadge(brandManagementKeys.invoice.status.self),
  },
  options: RowOptionsMenu({
    options,
    optionClick: optionMenuOptionClick,
  }),
});

export const snapshotTableComponents = tableComponentProps => ({
  [tabs.services]: servicesTableComponents(tableComponentProps),
  [tabs.users]: usersTableComponents(tableComponentProps),
  [tabs.plans]: plansTableComponents(tableComponentProps),
  [tabs.contracts]: contractsTableComponents(tableComponentProps),
  [tabs.invoices]: invoicesTableComponents(tableComponentProps),
});

const userSubtableComponents = {
  space: { display: Space },
  [brandManagementKeys.service.name]: {
    display: RowLabel(brandManagementKeys.service.name, {
      iconLeft: brandManagementKeys.service.icon,
    }),
  },
  [brandManagementKeys.service.addedAt]: {
    display: RowHumanReadableDate(brandManagementKeys.service.addedAt),
  },
  [brandManagementKeys.service.addedBy.name]: {
    display: User(
      brandManagementKeys.service.addedBy.name,
      brandManagementKeys.service.addedBy.image
    ),
  },
  [brandManagementKeys.service.roleName]: {
    display: RowLabel(brandManagementKeys.service.roleName, {
      size: '_XS',
      hasBorder: true,
      noPadding: false,
    }),
  },
};

export const ServiceRow = ({ service, selected, onClick }) => {
  const color = Colors.Primary._600;
  return (
    <ServiceRowCSS gap="_S" w onClick={onClick} selected={selected}>
      <RadioButton selected={selected} />
      <ServiceIconName gap="_2XS" align="center">
        <Icon name={service.icon} size={Size.Icon._M} color={color} />
        <BaseText text={service.text} color={color} />
      </ServiceIconName>
      <BaseText text={service.detail} bold={false} ellipsis />
    </ServiceRowCSS>
  );
};

export const MainContactToggle = ({ selected, toggleSelected }) => {
  return (
    <Flex align="center" gap="_XS" onClick={toggleSelected}>
      <Toggle size="_S" active={selected} />
      <BaseText text={'Assign as main contact of the brand'} />
    </Flex>
  );
};

export const SilentCreationToggle = ({
  onNotifyUserToggle,
  shouldNotifyUser,
}) => {
  return (
    <Flex align="center" gap="_XS" onClick={onNotifyUserToggle}>
      <Toggle size="_S" active={shouldNotifyUser} />
      <BaseText text={'Notify user via email'} />
    </Flex>
  );
};

export const IsContractedToggle = ({ selected, toggleSelected }) => {
  return (
    <Flex align="center" gap="_XS" onClick={toggleSelected}>
      <Toggle size="_S" active={selected} />
      <BaseText text={'Contracted brand'} />
    </Flex>
  );
};

export const PlanItem = ({ name, description, pricing, selected, onClick }) => {
  return (
    <PlanItemCSS onClick={onClick} justify="between" selected={selected}>
      <Flex gap="_S" align={'flex-start'}>
        <RadioButton selected={selected} style={{ marginBottom: 0 }} />
        <PlanText name={name} description={description} />
      </Flex>
      <Pricing {...pricing} />
    </PlanItemCSS>
  );
};

const PlanText = ({ name, description }) => {
  return (
    <Flex column gap="_2XS">
      <BaseText text={name} color={Colors.Primary._600} />
      <BaseText text={description} bold={false} size="_S" />
    </Flex>
  );
};

const Pricing = ({ setupFee, priceMonthly, priceCatch }) => {
  const blue = Colors.Primary._600;
  return (
    <PricingGrid>
      <GridGrayBG>
        <BaseText text={'Setup Fee'} size="_S" color={blue} />
      </GridGrayBG>
      <GridGrayBG>
        <BaseText text={'Price / Month'} size="_S" color={blue} />
      </GridGrayBG>
      <GridGrayBG>
        <BaseText text={'Price / Catch'} size="_S" color={blue} />
      </GridGrayBG>
      <BaseText text={setupFee} size="_S" />
      <BaseText text={priceMonthly} size="_S" />
      <BaseText text={priceCatch} size="_S" />
    </PricingGrid>
  );
};

export const EditImageIcon = ({ enableUpload, toggleEnableUpload }) => {
  return toggleEnableUpload ? (
    <div
      style={{
        position: 'absolute',
        top: Layout.Spacing._S,
        right: Layout.Spacing._S,
      }}
    >
      <AnimatedIcon
        onClick={toggleEnableUpload}
        name={enableUpload ? Icons.X : Icons.Edit3}
        theme="neutral"
      />
    </div>
  ) : null;
};
