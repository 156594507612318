import { PropKeys } from '../../constants';
import { API } from '../../lib/api';

export const login = async ({ email, password }) => {
  const body = {
    email,
    password,
  };

  const { data, status, error } = await API.POST('login', body);

  if (error || status !== 200) return { status, error };
  const { usPhone, auth_type } = data;

  return {
    status,
    [PropKeys.email]: email,
    [PropKeys.phone]: usPhone,
    [PropKeys.authType]: auth_type,
  };
};
