import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { Pages } from '../../routes/variables';
import { requestPasswordReset } from '../api/requestPasswordReset';
import ForgotPasswordLayout from '../components/ForgotPasswordLayout';
import { Fields, FormValues, Texts } from './variables';

const { forgotPassword } = Pages;

const {
  title,
  subtitle,
  minorButtonText,
  mainButtonText,
  minorLeftIcon,
  emailPlaceholder,
  successTitle,
  successSubtitle,
  successMinorButtonText,
  successMinorLeftIcon,
} = Texts[forgotPassword];

const { initialValues, validationSchema } = FormValues[forgotPassword];

const { emailField, captchaResponseField } = Fields[forgotPassword];

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState();
  const navigate = useNavigate();

  const { showSnackbarError } = useSnackbar();

  const captcha = useRef(null);

  const handleRequestPasswordReset = async ({ [emailField]: emailValue }) => {
    setShowCaptcha(true);
    setEmail(emailValue);
    setIsLoading(true);
  };

  const onVerify = async token => {
    const success = await requestPasswordReset({
      [emailField]: email,
      [captchaResponseField]: token,
    });

    if (!success) {
      showSnackbarError();
    }
    setSuccess(success);
  };

  const minorButtonClick = () => {
    const to = -1;
    navigate(to);
  };

  const successMinorButtonClick = () => {
    const to = -1;
    navigate(to);
  };

  return (
    <ForgotPasswordLayout
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleRequestPasswordReset}
      title={title}
      subtitle={subtitle}
      minorButtonClick={minorButtonClick}
      minorButtonText={minorButtonText}
      mainButtonText={mainButtonText}
      minorLeftIcon={minorLeftIcon}
      emailPlaceholder={emailPlaceholder}
      emailName={emailField}
      success={success}
      successTitle={successTitle}
      successSubtitle={successSubtitle}
      successMinorButtonClick={successMinorButtonClick}
      successMinorButtonText={successMinorButtonText}
      successMinorLeftIcon={successMinorLeftIcon}
      showCaptcha={showCaptcha}
      captcha={captcha}
      onVerify={onVerify}
      isLoading={isLoading}
    />
  );
};
export default ForgotPassword;
