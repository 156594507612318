import { Card } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Separator } from '../../../../../../components/atoms/Separator/Separator';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import { Input } from '../../../../../../components/molecules';
import Button from '../../../../../../components/molecules/Button/Button';
import ContractedBrandName from '../../../../../../components/molecules/ContractedBrandName/ContractedBrandName';
import Actions from '../../../../../../components/organisms/Actions/Actions';
import { Tabs } from '../../../../../../components/organisms/Tabs/Tabs';
import { Colors } from '../../../../../../components/style';
import NoSearchResults from '../../../../../../components/templates/NoSearchResults/NoSearchResults';
import { AllServices } from '../../../../../brand/features/brand_settings/components';
import { noResultsState } from '../../controllers/variables';
import { BaseText, SectionTitle, SnapshotTitle } from '../atoms';
import {
  ContactCard,
  EditImageIcon,
  IsContractedToggle,
  MainContactToggle,
  PlanItem,
  ServiceRow,
  SilentCreationToggle,
  tableComponents,
} from '../molecules';
import {
  AdminBrandsTableContainer,
  BrandDropzoneComponent,
  BrandFieldsCSS,
  BrandImage,
  BrandOverviewPlan,
  BrandSnapshotCSS,
  ContractDropzoneComponent,
  ContractFieldsCSS,
  InvoiceDropzoneComponent,
  InvoiceFieldsCSS,
  PersonalDetailsCSS,
} from '../style';

export const AdminBrandsTable = ({ tableProps }) => {
  return (
    <AdminBrandsTableContainer {...tableProps} components={tableComponents} />
  );
};

export const BrandOverview = ({
  brandImage,
  startColumnProps,
  endColumnProps,
}) => {
  return (
    <Card>
      <Flex w justify={'between'}>
        <Flex gap="_S">
          <BrandImage src={brandImage} />
          <StartColumn {...startColumnProps} />
        </Flex>
        <EndColumn {...endColumnProps} />
      </Flex>
    </Card>
  );
};

const StartColumn = ({ brandName, isContracted, brandGroupName, industry }) => {
  return (
    <Flex h column justify="between">
      <ContractedBrandName
        brand={brandName}
        isContracted={isContracted}
        size="_L"
      />
      <Flex column>
        <BaseText text={brandGroupName} />
        <BaseText text={industry} color={Colors.Neutral._500} />
      </Flex>
    </Flex>
  );
};

const EndColumn = ({ contractEnd, plan, contact }) => {
  return (
    <Flex h column justify="between" align={'end'}>
      <Flex gap="_XS" align={'center'}>
        <BaseText text={contractEnd} />
        {plan ? (
          <BrandOverviewPlan
            text={plan}
            textColor={Colors.Neutral._900}
            size="_S"
            hasBorder
          />
        ) : null}
      </Flex>
      <ContactCard {...contact} />
    </Flex>
  );
};

export const BrandSnapshots = ({
  displayTabs,
  activeTab,
  setActiveTab,
  component,
}) => {
  return (
    <BrandSnapshotCSS className={'brand-snapshots'}>
      <div style={{ position: 'relative', height: '48px' }}>
        <Tabs
          tabs={displayTabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <Separator />
      </div>
      <Flex grow> {component} </Flex>
    </BrandSnapshotCSS>
  );
};

export const SnapshotHeader = ({ title, button }) => {
  return (
    <Flex w justify={'between'}>
      <SnapshotTitle text={title} />
      {button ? <Button {...button} /> : ''}
    </Flex>
  );
};

export const ServicesList = ({ services, selected, toggleSelected }) => {
  return (
    <Flex column gap="_2XS" w scroll>
      <Spacer height={'_S'} />

      {services?.length ? (
        services.map((service, index) => (
          <ServiceRow
            key={service.id}
            service={service}
            selected={selected == index}
            onClick={toggleSelected(index)}
          />
        ))
      ) : (
        <NoSearchResults {...noResultsState.noServices} />
      )}
    </Flex>
  );
};

export const AddServiceActions = ({ actionsProps, hideSeparator }) => {
  return (
    <Flex relative w column>
      {hideSeparator ? null : (
        <Separator style={{ top: 0, background: Colors.Neutral._200 }} />
      )}
      <Spacer height={'_M'} />
      <Actions {...actionsProps} />
    </Flex>
  );
};

export const PersonalDetails = ({
  isEditing,
  personalDetailsProps,
  onNotifyUserToggle,
  shouldNotifyUser,
}) => {
  return (
    <Flex column gap="_S">
      <SectionTitle text={'Personal details'} />
      <PersonalDetailsCSS column gap="_XS">
        {personalDetailsProps.fields.map(field => (
          <Input {...field} />
        ))}
        <Spacer />
        <MainContactToggle {...personalDetailsProps} />
        {!isEditing && (
          <>
            <Spacer />
            <SilentCreationToggle
              onNotifyUserToggle={onNotifyUserToggle}
              shouldNotifyUser={shouldNotifyUser}
            />
          </>
        )}
      </PersonalDetailsCSS>
    </Flex>
  );
};

export const ServiceLevelEnabled = ({ serviceLevelEnabledProps }) => {
  return (
    <Flex column gap="_S">
      <SectionTitle text={'Services'} />
      <AllServices {...serviceLevelEnabledProps} />
    </Flex>
  );
};

export const PlanDetails = ({ planDetailsProps }) => {
  return (
    <Flex column gap="_S" w>
      <SectionTitle text={'Plan details'} />
      <PersonalDetailsCSS column gap="_XS">
        {planDetailsProps.fields.map(field => (
          <Input {...field} />
        ))}
      </PersonalDetailsCSS>
    </Flex>
  );
};

export const PlanList = ({ plans, selected, selectPlan }) => {
  return (
    <Flex column gap="_S" w>
      <SectionTitle text={'Plans'} />
      <Flex column gap="_XS">
        {plans.map((plan, index) => (
          <PlanItem
            {...plan}
            selected={selected == index}
            onClick={selectPlan(index)}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export const ContractFields = ({ contractFieldsProps }) => {
  return (
    <Flex column gap="_S" w>
      <ContractFieldsCSS column gap="_M">
        {contractFieldsProps.fields.map(field => (
          <Input {...field} />
        ))}
      </ContractFieldsCSS>
    </Flex>
  );
};

export const ContractDropzone = ({ contractDropzoneProps }) => {
  return (
    <ContractDropzoneComponent {...contractDropzoneProps}>
      <EditImageIcon {...contractDropzoneProps} />
    </ContractDropzoneComponent>
  );
};

export const InvoiceFields = ({ invoiceFieldsProps }) => {
  return (
    <Flex column gap="_S" w>
      <InvoiceFieldsCSS column gap="_M">
        {invoiceFieldsProps.fields.map(field => (
          <Input {...field} selectOption={invoiceFieldsProps.selectOption} />
        ))}
      </InvoiceFieldsCSS>
    </Flex>
  );
};

export const InvoiceDropzone = ({ invoiceDropzoneProps }) => {
  return (
    <InvoiceDropzoneComponent {...invoiceDropzoneProps}>
      <EditImageIcon {...invoiceDropzoneProps} />
    </InvoiceDropzoneComponent>
  );
};

export const BrandFields = ({ brandFieldsProps }) => {
  return (
    <Flex column gap="_S" w>
      <BrandFieldsCSS column gap="_M">
        {brandFieldsProps.fields.map(field => (
          <Input {...field} selectOption={brandFieldsProps.selectOption} />
        ))}
        <IsContractedToggle {...brandFieldsProps} />
      </BrandFieldsCSS>
    </Flex>
  );
};

export const BrandDropzone = ({ brandDropzoneProps }) => {
  return (
    <BrandDropzoneComponent {...brandDropzoneProps}>
      <EditImageIcon {...brandDropzoneProps} />
    </BrandDropzoneComponent>
  );
};
