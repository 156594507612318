import { Text } from '../../../components/atoms';
import { BrandLogo } from '../../../components/atoms/BrandLogo/BrandLogo';
import { Flex } from '../../../components/atoms/Flex/Flex';
import { Icons } from '../../../components/atoms/Icon/Icon.options';
import { Separator } from '../../../components/atoms/Separator/Separator';
import { Input, RadioButton } from '../../../components/molecules';
import Tooltip from '../../../components/molecules/Tooltip/Tooltip';
import { Colors, FontKeys, Layout } from '../../../components/style';
import { PropKeys, Utils } from '../../../constants';
import {
  BrandName,
  CardText,
  FavoriteIcon,
  PaperAirplane,
} from '../atoms/Atoms';
import { styles } from '../style';

export const SectionSeparator = ({ text }) => {
  return (
    <Flex w gap="_XS" padding={`${Layout.Spacing._XS} 0`}>
      <Flex relative w0 grow>
        <Separator style={{ bottom: '50%', top: '50%' }} />{' '}
      </Flex>
      <Text
        text={text}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Regular}
        color={Colors.Neutral._500}
      />
      <Flex relative w0 grow>
        <Separator style={{ bottom: '50%', top: '50%' }} />{' '}
      </Flex>
    </Flex>
  );
};

export const EmailInput = ({ placeholder, value, onChange, name }) => {
  return (
    <Input
      name={name}
      placeholder={placeholder}
      variant="default"
      size="_M"
      value={value}
      onChange={onChange}
      leftIcon={Icons.Mail}
      hasError
    />
  );
};

export const Code2FAInput = ({
  placeholder,
  value,
  onChange,
  name,
  maxLength,
}) => {
  return (
    <Input
      name={name}
      placeholder={placeholder}
      variant="default"
      size="_M"
      value={value}
      onChange={onChange}
      leftIcon={Icons.Shield}
      maxLength={maxLength}
      autoFocus
    />
  );
};

export const Option2FA = ({
  hoverIn,
  hoverOut,
  selected,
  setSelected,
  id,
  label,
  disabled,
  tooltipText,
}) => {
  return (
    <Tooltip
      content={disabled ? tooltipText : ''}
      sizeOffset={0}
      children={
        <RadioButton
          selected={selected}
          setSelected={setSelected}
          id={id}
          label={label}
          disabled={disabled}
          hoverIn={hoverIn}
          hoverOut={hoverOut}
        />
      }
    />
  );
};

export const PasswordRequestSuccess = ({ text }) => {
  return (
    <div style={styles.passwordRequestSuccess}>
      <PaperAirplane />
      <CardText text={text} />
    </div>
  );
};

export const BrandSwitchItem = ({
  isCurrentBrand: selected,
  favorite,
  toggleFavorite,
  handleBrandSwitch,
  brand,
  hideFavorite,
}) => {
  const { [PropKeys.brandImage]: image, [PropKeys.brandName]: name } = brand;
  return (
    <Flex justify="between" align="center">
      <Flex gap="_XS" align="center" onClick={handleBrandSwitch(brand)}>
        <RadioButton
          size="_S"
          selected={selected}
          setSelected={Utils.emptyFunction}
          label=""
        />
        <BrandLogo
          variant={'brand'}
          src={image}
          height={Layout.Spacing._M}
          selected={selected}
          border
        />
        <BrandName text={name} selected={selected} />
      </Flex>
      {hideFavorite ? null : (
        <FavoriteIcon onClick={toggleFavorite(brand)} favorite={favorite} />
      )}
    </Flex>
  );
};
