import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { usePermissions } from '../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { NavURIs, Pages } from '../../routes/variables';
import { login as doLogin } from '../api/login';
import { request2FAcode } from '../api/request2FAcode';
import { verify2FAcode } from '../api/verify2FAcode';
import LoginLayout from '../components/LoginLayout';
import { Fields, FormValues, Texts } from './variables';

const { login, setup2FA, verify2FA, forgotPassword } = Pages;
const {
  [setup2FA]: setup2FAroute,
  [verify2FA]: verify2FAroute,
  [forgotPassword]: forgotPasswordRoute,
} = NavURIs;
const {
  title,
  subtitle,
  emailPlaceholder,
  minorButtonText,
  mainButtonText,
  passwordPlaceholder,
} = Texts[login];

const { initialValues, validationSchema } = FormValues[login];

const { emailField, passwordField } = Fields[login];

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [_, setCredentials, { initVerifiedUser }] = useAuth();

  const { showSnackbarError } = useSnackbar();
  const { clearPermissions } = usePermissions();

  const navigate = useNavigate();

  const handleForgotPassword = () => {
    const to = forgotPasswordRoute;
    navigate(to);
  };

  const handleLogin = async ({ email, password }) => {
    const userData = await doLogin({ email, password });
    if (userData.error || userData.status !== 200) {
      const errorMessage =
        userData.status === 401
          ? 'No account found with these credentials'
          : 'Something went wrong';
      showSnackbarError(errorMessage);
      return;
    }
    clearPermissions();
    const { authType, phone } = userData;
    const newCredentials = {
      password,
      email,
      phone,
    };

    if (authType == null) {
      setCredentials(newCredentials);
      const to = setup2FAroute;
      navigate(to);
      return;
    }

    const code = await request2FAcode({
      email,
      password,
      authType,
    });

    if (!code) {
      const to = setup2FAroute;
      navigate(to);
      return;
    }

    const { userId, authCode } = code;
    setCredentials({ ...newCredentials, userId, authCode, authType });

    const state = { authType };
    const to = verify2FAroute;
    navigate(to, { state });
  };

  const handleGoogleLogin = async ({ credential }) => {
    setIsLoading(true);
    if (credential) handleGoogleSuccess({ credential });
    else handleGoogleFailure();
  };

  const handleGoogleSuccess = async ({ credential }) => {
    try {
      const response = await verify2FAcode({
        credential,
      });
      if (!response) handleGoogleFailure();
      else initVerifiedUser({ response });
    } catch {
      handleGoogleFailure();
    }
  };

  const handleGoogleFailure = () => {
    showSnackbarError('Could not login with Google');
    setIsLoading(false);
  };

  return (
    <LoginLayout
      title={title}
      subtitle={subtitle}
      emailPlaceholder={emailPlaceholder}
      emailName={emailField}
      passwordPlaceholder={passwordPlaceholder}
      minorButtonClick={handleForgotPassword}
      passwordName={passwordField}
      minorButtonText={minorButtonText}
      mainButtonText={mainButtonText}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleLogin}
      handleGoogleLogin={handleGoogleLogin}
      isLoading={isLoading}
    />
  );
};

export default Login;
