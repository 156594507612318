import { Form, Formik } from 'formik';
import { useRef } from 'react';

import Reaptcha from 'reaptcha';
import { Card } from '../../../components/atoms';
import { Flex } from '../../../components/atoms/Flex/Flex';
import {
  ButtonPrimaryMedium100,
  ButtonTertiaryMedium,
} from '../../../components/molecules/Button/Button.export';
import { Layout } from '../../../components/style';
import { PropKeys } from '../../../constants';
import { isEmpty } from '../../../lib/js';
import { Pages } from '../../../routes/variables';
import { Fields } from '../../controllers/variables';
import {
  BrandCatalogSectionTitle,
  Placeholder,
  TitleText,
} from '../atoms/Atoms';
import { BrandSwitchItem, Option2FA } from '../molecules/Molecules';
import { SuccessCardCSS, styles } from '../style';

const { verify2FA } = Pages;
const { codeField } = Fields[verify2FA];

export const InfoCard = ({
  initialValues,
  validationSchema,
  onSubmit,
  title,
  children,
  minorButtonClick,
  minorButtonText,
  mainButtonText,
  mainRightIcon,
  minorLeftIcon,
  minorRightIcon,
  isLoading,
  captcha,
  showCaptcha,
  onCaptchaVerify,
}) => {
  const firstAttempt = useRef(true);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid, isSubmitting, values, submitForm }) => {
        if (firstAttempt.current && values?.[codeField]?.length === 6) {
          firstAttempt.current = false;
          setTimeout(submitForm, 0);
        }
        return (
          <Form style={styles.infoCard}>
            <Card padding={Layout.Spacing._M} title={title} type="form">
              <div style={styles.card}>
                {children}
                <div style={styles.cardConstants}>
                  <ButtonPrimaryMedium100
                    type="submit"
                    text={mainButtonText}
                    disabled={
                      !isValid ||
                      (!isEmpty(initialValues) && !dirty) ||
                      isSubmitting ||
                      isLoading
                    }
                    isLoading={isSubmitting || isLoading}
                    rightIcon={mainRightIcon}
                  />
                  {showCaptcha && (
                    <Flex justify="center" align="center">
                      <Reaptcha
                        ref={captcha}
                        sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
                        onVerify={onCaptchaVerify}
                      />
                    </Flex>
                  )}
                  <ButtonTertiaryMedium
                    text={minorButtonText}
                    onClick={minorButtonClick}
                    disabled={isSubmitting}
                    leftIcon={minorLeftIcon}
                    rightIcon={minorRightIcon}
                  />
                </div>
              </div>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
};

export const InfoCardFlexibleHeight = ({
  initialValues,
  validationSchema,
  onSubmit,
  title,
  children,
  minorButtonClick,
  minorButtonText,
  mainButtonText,
  mainRightIcon,
  minorLeftIcon,
  minorRightIcon,
  customIsValid,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid, isSubmitting }) => (
        <Form style={styles.infoCard}>
          <Card
            shadow="_M"
            padding={Layout.Spacing._M}
            // !? would we rather allow for custom style override,
            // or should we constrain customizable properties
            style={styles.flexibleHeightCard}
          >
            <Flex gap="_M" column>
              <TitleText text={title} />
              {children}
            </Flex>
            <Flex gap="_M" column>
              <ButtonPrimaryMedium100
                type="submit"
                text={mainButtonText}
                disabled={
                  !isValid ||
                  customIsValid === false ||
                  (!isEmpty(initialValues) && !dirty) ||
                  isSubmitting
                }
                isLoading={isSubmitting}
                rightIcon={mainRightIcon}
              />
              <ButtonTertiaryMedium
                text={minorButtonText}
                onClick={minorButtonClick}
                disabled={isSubmitting}
                leftIcon={minorLeftIcon}
                rightIcon={minorRightIcon}
              />
            </Flex>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export const Options2FA = ({ options }) => {
  return (
    <div style={styles.options2FA}>
      {options.map(option => (
        <Option2FA {...option} />
      ))}
    </div>
  );
};

export const SuccessCard = ({
  title,
  children,
  minorButtonClick,
  minorButtonText,
  minorLeftIcon,
}) => {
  return (
    <SuccessCardCSS>
      <Card shadow="_M" padding={Layout.Spacing._M}>
        <TitleText text={title} />
        {children}
        <ButtonTertiaryMedium
          text={minorButtonText}
          onClick={minorButtonClick}
          leftIcon={minorLeftIcon}
        />
      </Card>
    </SuccessCardCSS>
  );
};

export const BrandsCatalog = ({
  title,
  brands,
  favorite,
  handleBrandSwitch,
  toggleFavorite,
  hideFavorite,
  placeholder,
}) => {
  return (
    <Flex column gap="_XS">
      <BrandCatalogSectionTitle text={title} />
      {brands.length ? (
        brands.map(brand => (
          <BrandSwitchItem
            brand={brand}
            favorite={favorite}
            isCurrentBrand={brand[PropKeys.isCurrentBrand]}
            handleBrandSwitch={handleBrandSwitch}
            toggleFavorite={toggleFavorite}
            key={brand[PropKeys.brandId]}
            hideFavorite={hideFavorite}
          />
        ))
      ) : (
        <Placeholder text={placeholder} />
      )}
    </Flex>
  );
};
